import logo from './logo.svg';
import './App.css';
import Loading from './Loading';


import * as React from 'react';
import {useEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
          width: 0,
        },
      }),
    },
  }),
);


function App() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };


  let UserAuthContainer = () => <Container style={{ paddingTop: "16px" }}></Container>;




  useEffect(() => {

    initImageCanvas();
   
   /*const grayButton = document.querySelector('input[value="Grayscale"]')
   
   grayButton.addEventListener('click', () => {

    resetImage();
     const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
     for (let i = 0; i < imgData.data.length; i += 4) {
         const r = imgData.data[i],
             g = imgData.data[i + 1],
             b = imgData.data[i + 2];
         imgData.data[i] = imgData.data[i + 1] = imgData.data[i + 2] = r;
     }
     context.putImageData(imgData, 0, 0)
   })*/
  });

  const initImageCanvas = () => {
    const canvas = document.getElementById("canvas"),
    context = canvas.getContext('2d')
   const imgSrc = document.querySelector('input[type="file"]')
   
   if(typeof(window.ORIGINAL_IMAGE_DATA) == "undefined"){
    window.ORIGINAL_IMAGE_DATA = null;
   }
   
   const cacheImageData = () => {
     const original = context.getImageData(0, 0, canvas.width, canvas.height).data
     window.ORIGINAL_IMAGE_DATA = new Uint8ClampedArray(original.length)
     for (let i = 0; i < original.length; i += 1) {
      window.ORIGINAL_IMAGE_DATA[i] = original[i]
     }
   }

   //cacheImageData();
   
   const drawImage = img => {
     canvas.height = img.height
     canvas.width = img.width
     context.drawImage(img, 0, 0, img.width, img.height)
     cacheImageData()
   }
   const loadImage = e => {
     const img = new Image()
     img.src = e.target.result
     img.addEventListener('load', () => {
         drawImage(img)
     })
   }
   const detectImageInput = e => {
     const file = e.target.files[0],
         fr = new FileReader()
     if (!file.type.includes("image")) return
     fr.addEventListener('load', loadImage)
     fr.readAsDataURL(file)
   }
   imgSrc.addEventListener('change', detectImageInput)

   //detectImageInput();

   return { context, canvas};
  }


    const resetImage = () => {
      const { context, canvas } = initImageCanvas();

      const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
      for (let i = 0; i < imgData.data.length; i += 1) {
          imgData.data[i] = window.ORIGINAL_IMAGE_DATA[i]
      }
      context.putImageData(imgData, 0, 0)
  };

   
    const onRedButtonClick = () => {
      resetImage();

      const { context, canvas } = initImageCanvas();
      
      
     const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
     for (let i = 0; i < imgData.data.length; i += 4) {
         const r = imgData.data[i],
             g = imgData.data[i + 1],
             b = imgData.data[i + 2];
         imgData.data[i] = r;
         imgData.data[i + 1] = 0;
         imgData.data[i + 2] = 0;
     }
     context.putImageData(imgData, 0, 0)
   };
   
   const onBlueButtonClick = () => {
    resetImage();  

    const { context, canvas } = initImageCanvas();
      
     const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
     for (let i = 0; i < imgData.data.length; i += 4) {
         const r = imgData.data[i],
             g = imgData.data[i + 1],
             b = imgData.data[i + 2];
         imgData.data[i] = 0;
         imgData.data[i + 1] = 0;
         imgData.data[i + 2] = b;
     }
     context.putImageData(imgData, 0, 0)
   };




  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Tom Turbo Tricks
            </Typography>
            <IconButton color="inherit">
              {/*               <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Box>
            <UserAuthContainer />
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
            <Grid container spacing={1}>

              <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
                <div className={"fileLoader"}>
                  <h3>Geheimbrille</h3>
                  <div className="iconHeader">
                    <PhotoCameraIcon/>
                  </div>
                  <input type="file" accept="image/*" />
                </div>

                <div className="menu">
                  <input type="button" value="Grayscale" className="btn" style={{"display": "none"}} />
                  <input type="button" onClick={onBlueButtonClick} className="btn blue" />
                  <input type="button" onClick={onRedButtonClick} className="btn red" />
                </div>
                <canvas id="canvas"></canvas>
                </Paper>
              </Grid>

            </Grid>

          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
